.page--breast-cancer-care {
  @include media-query(small-palm) {
    text-align: center;
  }
}

.breast-cancer-care__logo {
  @include media-query(small-palm) {
    margin-bottom: em($spacing-unit);
  }
}

.breast-cancer-care__infographic {
  margin-bottom: em($spacing-unit);
}

.breast-cancer-care__boxes {
  margin-top: em(-$spacing-unit--large);
  margin-bottom: em(-$spacing-unit--large);
}

.breast-cancer-care__box {
  padding: em($spacing-unit);
  text-align: center;

  @include media-query(lap-and-up) {
    height: em(323px);
    display: table-cell;
    vertical-align: middle;
    margin-top: em(-$spacing-unit);
  }
}

.breast-cancer-care__box--donate {
  background: $green--extra-light;
}

.bcc-button-group {
  @media only screen and (max-width: 691px) {
    .button {
      margin: em($spacing-unit--tiny) 0;
    }
  }
}

.breast-cancer-care__blog {
  margin-bottom: sb-rem($spacing-unit);
}
