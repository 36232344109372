@import "../helpers/buttons";

.button {
  @extend .btn;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0;
  white-space: normal;
  padding: .7em 1.5em;
  border-radius: $border-radius-base;

  &:active,
  &.active {
    box-shadow: none; // Undo .btn style
  }
}

.button--primary {
  @include button-color($color-primary, transparent, $color-primary);
}

.button--primary--solid {
  @include button-color(#fff, $color-primary, $color-primary, true);
}

.button--primary--white {
  @extend .button--primary;
  color: #fff;
  border-color: #fff;
}

.button--alt {
  @include button-color($color-primary, transparent, $color-primary);
}

.button--alt--solid {
  @include button-color(#fff, $color-primary, $color-primary, true);
}

.button--pale--solid {
  @include button-color($green--dark, $green--lightest, #fff, true);
}

.button--premium {
  @include button-color($gold, transparent, $gold);
}

.button--premium--solid {
  @include button-color(#fff, $gold, $gold, true);
}

.button-link {
  @extend .btn-link;
  cursor: pointer;
  padding: 0;
}
