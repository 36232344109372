@use "sass:color";

@mixin button-color($color, $background, $border: transparent, $solid: false) {
  color: $color;
  background-color: $background;
  border-color: $border;

  @if $solid {
    &:hover,
    &:focus,
    &.focus {
      color: $color !important;
      background-color: color.adjust($background, $lightness: -5%) !important;
      border-color: color.adjust($border, $lightness: -10%);
    }

    &:active,
    &.active {
      color: $color !important;
      background-color: color.adjust($background, $lightness: -10%) !important;
      border-color: color.adjust($border, $lightness: -10%);
    }
  } @else {
    &:hover,
    &:focus,
    &.focus {
      color: #fff !important;
      background-color: $color !important;
      border-color: $border !important;
    }

    &:active,
    &.active {
      color: #fff !important;
      background-color: color.adjust($color, $lightness: -10%) !important;
      border-color: $border !important;
    }
  }
}
