@use "sass:math";

@import "../../shared/helpers/truncate";

.offer,
.offer__container {
  &, &:hover {
    color: $grey--darkest;
  }
}

.offer {
  box-shadow: 0 2px 10px 1px rgba(#000, 0.1);
  display: block;
  margin-bottom: sb-rem($spacing-unit);
  background: #fff;
  border-radius: $border-radius-base;
  overflow: hidden;
}

.offer--full-width {
  display: flex;
  flex-direction: column;
  align-items: center;

  :has(> &) {
    container-type: inline-size;
  }

  @container (width > 600px) {
    flex-direction: row;
    flex-wrap: wrap;

    .offer__img-container {
      flex-shrink: 0;
      flex-basis: calc(100% / 3);
      min-width: 320px;
      height: auto !important;
      border-bottom: none;
      border-right: sb-rem(7px) solid;

      @include media(tab) {
        width: 50%;
        min-width: auto;
      }
    }

    .offer__container {
      flex: 1;
      overflow: hidden; // required by flex items for text-overflow: ellipsis
    }
  }
}

.offer__img-container {
  position: relative;
  height: sb-rem(225px);
  color: $green;
  border-bottom: sb-rem(7px) solid;

  @include media(palm) {
    height: sb-rem(275px);
  }
}

.offer__img {
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all $transition-duration--short;
}

.offer__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: sb-rem($spacing-unit--small);

  @include media(tab-and-up) {
    padding: sb-rem($spacing-unit);
  }
}

.offer__header,
.offer__content {
  margin-bottom: sb-rem($spacing-unit--small);
}

.offer__from-price {
  float: right;
  font-size: sb-rem(14px);
  line-height: 1;
  margin: 0 0 sb-rem($spacing-unit--small) sb-rem($spacing-unit--small);
  text-align: right;

  .price {
    font-size: sb-rem(32px);
  }

  .call {
    font-size: sb-rem(20px);
    white-space: nowrap;
  }
}

.offer__title {
  margin-bottom: 0;
  display:inline;
  padding-right: em($spacing-unit--tiny);
  font-weight: 400;
}

.offer__subtitle {
  font-size: sb-rem(12px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $grey;
  @include truncate-text;
}

.offer__content {
  h4,
  p {
    margin-bottom: .75rem; // For rendered markdown
  }
}

.offer__footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media(mobile) {
    flex-direction: row;
    width: auto;
  }
}

.offer__more-info {
  flex: 1;

  @include media(small-palm) {
    margin-bottom: sb-rem($spacing-unit--small);
  }
}

.offer__ctas {
  width: 100%;
  text-align: center;
  font-weight: bold;

  @include media(palm-and-up) {
    text-align: right;

    .offer__more-info + & {
      width: auto;
      margin-left: sb-rem($spacing-unit--tiny);
    }
  }
}

.offer__cta {
  @extend .button;
  @extend .button--alt--solid;

  & + & {
    margin-top: sb-rem($spacing-unit--small);

    @include media(palm-and-up) {
      margin-top: 0;
      margin-left: sb-rem($spacing-unit--tiny);
    }
  }

  @include media-query(small-palm) {
    display: block;
    width: 100%;
  }
}

//*** New package boxes ***//

.package {
  background-color: #fff;
  margin-bottom: $space-lg;
  box-shadow: $ui-box-shadow-heavy;
  border-radius: $ui-border-radius-lg;

  > div {
    display: flex;
    flex-flow: row wrap;
    padding: $space-sm;

    &:first-child {
      padding-bottom: 0;
    }

    &:nth-child(2) {
      padding: $space-3xs $space $space-xs;
    }

    &:last-child {
      border-top: 1px solid $grey--lighter;
    }
  }

  &__header {
    justify-content: space-between;

    h3 {
      margin-bottom: 0;
    }
  }

  &__overview,
  &__summary {
    width: 100%;
  }

  &__summary {
    padding-bottom: $space-sm;

    ul {
      margin: 0;
      padding: 0;
      margin-bottom: $space-3xs;

      li {
        display: inline-block;
        text-transform: uppercase;
        font-size: sb-rem(13.5px);
        font-weight: 700;
        color: $green;

        &:before {
          content: '\00a0\2022\00a0\00a0';
        }

        &:first-child:before {
          content: none;
        }
      }
    }
  }

  &__overview {
    ul {
      padding-inline-start: 20px;
      margin-bottom: $space-xs;
    }

    h4 {
      margin-bottom: $space-3xs;
      line-height: 1.75;
      font-weight: 700;
      color: $green;
    }
  }

  &__footer {
    color: $grey--dark;
    font-size: 0.875rem;

    .arrival {
      width: 70%;
      display: flex;
      align-items: center;
    }

    button {
      width: 100%;
      margin-top: $space-sm;
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      text-align: right;
      width: 30%;

      span {
        font-size: 1.2rem;
        color: $grey--darker;
        line-height: 1;
        padding-left: 0.2rem;
      }
    }
  }
  @include media(tab-and-up) {
    > div {
      padding: $space;
    }

    &__summary {
      width: 55%;
      padding-right: $space-sm;
    }

    &__overview {
      border-left: 1px solid $grey--lighter;
      width: 45%;

      h4 {
        padding-inline-start: 20px;
      }

      ul {
        padding-inline-start: 40px;
        margin-bottom: 0;
      }
    }

    &__footer {
      .arrival {
        width: 50%;
      }

      button {
        width: 20%;
        margin-top: 0;
      }

      .price {
        width: 30%;
        padding-right: $space-sm;
      }
    }
  }
  @include media(desk) {
    &__summary {
      width: 65%;
    }

    &__overview {
      width: 35%;
    }
  }
}
