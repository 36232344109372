@import "config/config";
@import "helpers/units"; // `em` function
@import "helpers/fontawesome"; // `fa-pseudo`
@import "helpers/utilities"; // `user-select-none`

/**
 * Markup:
 * <{a,button} class="ui-component-filter
 *                    wrap:{nowrap*, normal}
 *                    {active, selected, disabled}"> // `selected` automatically adds a pseudo-element "close" icon
 *   <i class="far fa-<icon>" aria-hidden="true"></i> // optional icon
 *   Filter text
 *   <span>Optional subtext</span>
 * </{a,button}>

 */
.ui-component-filter {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: inline-flex;
  column-gap: $space-3xs;
  align-items: center;
  white-space: nowrap;
  line-height: 1rem;
  cursor: pointer;
  transition: all .2s;
  padding: var(--space-2xs) var(--space-xs);
  background-color: #fff;
  border: 1px solid $grey-400;
  border-radius: 2em;
  box-shadow: $ui-box-shadow;
  color: $grey-400;
  text-decoration: none;
  @include user-select-none;

  &:hover,
  &.active,
  &.selected {
    &:not(.disabled) {
      border-color: $sb-green;
      color: $sb-green;
      background-color: $sb-green-100;
    }
  }

  &.wrap\:normal {
    white-space: normal;
  }

  &.active,
  &.selected {
    font-weight: 700;
  }

  &.selected {
    align-items: baseline;

    &::after {
      @include fa-pseudo("\f00d"); // fa-times
      @include text-small;
      padding: 0 2px;
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
