@use "sass:math";

@import "../ui_components/config/config";
@import "../ui_components/helpers/background"; // background-extend
@import "../ui_components/helpers/media-queries";
@import "../ui_components/helpers/wrapper";

.home__achievements {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: $space-xl;
    list-style: none;
    margin: 0 auto !important;
    padding: 0;

    li {
      flex-basis: 50%;
      text-align: center;

      @include tablet-portrait-up {
        flex-basis: 25%;
      }

      > :first-child {
        font-size: $base-font * math.pow($scale, 3);
        font-weight: 700;
      }
    }
  }
}

.home__buy-voucher {
  padding-bottom: 0;
  margin-bottom: var(--mixin-clamped);

  &::before {
    transform: rotate(180deg);
  }

  > article header {
    &::before, &::after {
      position: absolute;
      z-index: -1;
      color: $sb-green;
      font-size: clamp(7rem, 3.5rem + 7vw, 9rem);
      opacity: .1;
    }

    &::before {
      left: 0%;
      top: 50%;
      transform: rotate(22.5deg);
      @include fa-pseudo("\f06b", 300); // fa-gift
    }

    &::after {
      left: 100%;
      top: 0%;
      transform: rotate(15deg) translate(-100%, 50%);
      @include fa-pseudo("\f663", 300); // fa-gift-card
    }
  }

  form fieldset {
    justify-content: center;
  }
}

.home__popular-categories,
.home__popular-regions,
.home__competition-newsletter,
.home__spa-guides-blog {
  .ui-component-card {
    flex-basis: 0 !important;
    min-width: 300px;
  }
}

.home__competition-newsletter {
  .utils\:loop::after {
    bottom: 100%;
    right: -10%;
  }

  .ui-component-card {
    max-width: none !important;
  }
}

.home__popular-regions {
  &::before {
    transform: rotate(180deg);
    background-size: 100% 70% !important;
  }
}

.home__about-us {
  --blockwave-height: 400px;

  &::before {
    top: calc(var(--blockwave-height) / 2);
    bottom: auto;
    height: calc(var(--blockwave-height) / 2);
  }

  &::after {
    @include background-extend;
    background-color: $sb-green-100;
    top: var(--blockwave-height);
  }

  .ui-component-banner {
    min-height: var(--blockwave-height); // full-height on mobile
    margin-bottom: $space-xl;
    box-shadow: none !important;
  }

  article {
    @include wrapper(sm);
  }
}
