@use "sass:color";

@import "bootstrap/variables";
@import "../helpers/backgrounds";
@import "../ui_components/config/config";
@import "../ui_components/helpers/fontawesome";

.elm-datepicker {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  padding: $spacing-unit;
  padding-bottom: 0;
  color: $black;
  width: 100%;
  min-width: calc(7 * 2.5em + 2 * #{$spacing-unit--small});
  overflow: auto;
  border-top: 1px solid $grey--lightest;
  border-radius: $border-radius-base;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

  &.--open {
    visibility: visible;

    @include media-query("mobile") {
      overflow: visible; // So pseudo element can fill height of screen

      &::before {
        display: block;
        content: "";
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 100dvh;
        background: rgba($black, 0.8);
        pointer-events: none; // So clicking on overlay blurs the datepicker input
      }

      html:has(&),
      body:has(&) {
        overflow: clip;
      }

      body:has(&) #gtm-whatsapp {
        z-index: 0 !important;
      }
    }

    div:has(> &) {
      position: relative;
      width: 100%;
    }
  }

  &.elm-datepicker--pull-right {
    right: 0;
  }

  &.sit-left {
    left: 0;
    right: auto;
    transform: none !important;
  }

  &.sit-right {
    left: auto;
    right: 0;
    transform: none !important;
  }

  &.sit-top {
    top: 0;
    bottom: auto;
  }

  &.sit-bottom {
    top: auto;
    bottom: 0;
  }

  @include media-query("tab-and-up") {
    position: absolute;
    bottom: auto;
    left: 50%;
    width: 400px;
    border-top: 0;
    transform: translateX(-50%);
  }

  .dismiss-button {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 0;
    font-size: 1.5rem;
    color: $green;
    background-color: #fff;

    .theme\:premium & {
      color: $gold;
    }

    &::after {
      @include fa-pseudo("\f00d"); // fa-times
      padding: $spacing-unit--tiny;
      text-decoration: none;
    }

    @include media-query(tab-and-up) {
      display: none;
    }
  }

  table {
    border-collapse: collapse;
    max-width: 100%;
    text-align: center;
  }

  .controls {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $spacing-unit--small;
    background-color: #fff;
  }

  .prev,
  .next {
    cursor: pointer;
    padding: $spacing-unit--tiny;
    @include circle-bg($fa-fw-width, transparent);

    &.disabled {
      cursor: default;
      opacity: .2;
    }

    &:not(.disabled):hover {
      background-color: $grey--lighter;
    }
  }

  .prev {
    margin-left: -$spacing-unit--tiny;
  }

  .next {
    margin-right: -$spacing-unit--tiny;
  }

  .weekday {
    font-size: 0.85em;
    font-weight: 400;
    padding-bottom: $spacing-unit--small;
    color: $grey--dark;
  }

  .day {
    border: none;
    background: white;
    padding: 0;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;

    > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2.5em;
      height: 2.5em;
      margin: 3px 0;
      padding: .75em 0;
      border: 2px solid transparent;
      border-radius: 50%;
    }

    &.selected,
    &.highlighted {
      > span {
        color: $green;
        border-color: $green;

        .theme\:premium & {
          color: $gold;
          border-color: $gold;
        }
      }
    }

    &.flexible > span {
      background-color: $green--lightest;

      .theme\:premium & {
        background-color: color.adjust($gold, $lightness: 40%);
      }
    }

    &.other-month {
      color: #dddddd;
    }

    &.today {
      border: 1px solid #0089ec;
    }

    &.past {
      background: $grey--lightest;
      color: #dddddd;
      cursor: auto;
    }

  }

  .action-buttons {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $spacing-unit--small;
    padding-bottom: $spacing-unit;
    margin-top: auto;
    background-color: #fff;

    > button {
      width: auto !important;

      &:last-child {
        padding: $spacing-unit--tiny $spacing-unit--small !important;
      }
    }

    @include media-query("tab-and-up") {
      position: static;
      border-top: 0;
    }
  }

  &.elm-datepicker--search {
    top: 0;
    bottom: auto;
    left: 0;
    min-height: 100%;
    padding-top: 0;
    transform: translateX(0);

    &.--open::before {
      display: none;
    }

    @include media-query("tab-and-up") {
      top: auto;
      height: auto;
    }

    .controls {
      padding-top: $spacing-unit + $spacing-unit--small;

      @include media-query(tab-and-up) {
        padding-top: $spacing-unit;
      }
    }

    .action-buttons {
      border-top: 1px solid $grey--lightest;
    }
  }
}
